export default{
    methods: {
        parseDate(date) {
            const date_ob = new Date(date);
            const year = date_ob.getFullYear();
            const month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
            const day = ("0" + date_ob.getDate()).slice(-2);

            return `${day}.${month}.${year}`
        }
    }
}