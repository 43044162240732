<template>
    <b-modal id="user-status" size="lg" centered hide-footer>
        <div class="modal-wrapper">
            <div class="h1">Изменение статуса пользователя</div>
            <div v-if="user" class="user-wrap text-center">
                <div class="mb-4">
                    Выберите статус пользователя <strong class="user-name">{{user.lastName}} {{user.firstName}} {{user.patroName}}</strong> для изменения его возможностей в приложении.
                </div>
                <div class="mb-4">
                    <b-form-radio v-model="userBan" :value="true" name="ban">Заблокирован</b-form-radio>
                    <b-form-radio v-model="userBan" :value="false" name="ban">Незаблокирован</b-form-radio>
                </div>
                <b-button type="button" variant="primary submit pl-5 pr-5" @click="handleStatus()">сохранить</b-button>
            </div>
            <div v-else class="text-center">Пользователя не существует</div>
        </div>
    </b-modal>
</template>

<script>

    export default {
        name: "UserStatus",
        props: ['user'],
        methods: {
            handleStatus(){
                const data = {
                    ban: this.userBan,
                    userId: this.user._id,
                    language: this.user.languageCode
                };
                this.$emit('updateStatus', data);
            }
        },
        watch: {
            user(value){
                this.userBan = value.ban
            }
        },
        data(){
            return{
                userBan: false,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .h1{
        text-align: center;
        margin-bottom: 30px;
    }
    .user-name{
        text-transform: capitalize;
    }
    .user-wrap{
        max-width: 480px;
        margin: 0 auto;
    }
    .custom-radio{
        display: inline-block;
        margin: 5px 20px 5px 0;
    }
</style>