<template>
    <span class="status" :class="status ? 'baned' : ''">
        {{parseStatus(status)}}
    </span>
</template>

<script>
    export default {
        name: "BanStatus",
        props: {
            status: {
                type: Boolean,
                required: true
            }
        },
        methods: {
            parseStatus(status){
                if(status){
                    return 'заблокирован'
                }
                return 'не заблокирован'
            }
        }
    }
</script>

<style lang="scss" scoped>
    .status{
        font-size: 12px;
        color: #fff;
        text-transform: uppercase;
        padding: 5px 10px;
        background: #5cb82c;
        border-radius: 2px;
        text-align: center;
        &.baned{
            background: #f15959;
        }
    }
</style>