<template>
    <div class="dashboard">
        <div class="wrapper">
            <div class="page-title">
                <div class="h1">Список жалоб</div>
            </div>
            <div class="auto-scroll-horizontal">
                <b-table
                    :busy="isBusy"
                    :fields="fields"
                    :items="claimsProvider"
                    class="mb-0"
                    empty-text="Нет доступных жалоб"
                    show-empty
                    :current-page="currentPage"
                    :per-page="perPage"
                    ref="claimTable"
                >
                    <template #cell(owner)="data">
                        <div v-if="data.item.owner" class="user-td">
                            <img v-if="data.item.owner.avatarPath" :src="data.item.owner.avatarPath" alt="">
                            <div class="user-name">
                                {{data.item.owner.lastName}}
                                {{data.item.owner.firstName}}
                                {{data.item.owner.patroName}}
                            </div>
                        </div>
                        <div v-else>-</div>
                    </template>

                    <template #cell(createdAt)="data">
                        {{ parseDate(data.item.createdAt) }}
                    </template>

                    <template #cell(target)="data">
                        <div v-if="data.item.target" class="user-td">
                            <img v-if="data.item.target.avatarPath" :src="data.item.target.avatarPath" alt="">
                            <div class="user-name">
                                {{data.item.target.lastName}}
                                {{data.item.target.firstName}}
                                {{data.item.target.patroName}}
                            </div>
                        </div>
                        <div v-else>-</div>
                    </template>

                    <template #cell(status)="data">
                        <div class="status-cell">
                            <ban-status v-if="data.item.target" :status="data.item.target.ban" />
                            <button :disabled="!data.item.target" class="btn-crl" @click="handleBan(data.item.target)">
                                <b-icon icon="pencil-fill"></b-icon>
                            </button>
                        </div>
                    </template>

                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                    </template>

                    <template #empty="scope">
                        <div class="text-center my-2">
                            {{ scope.emptyText }}
                        </div>
                    </template>

                    <template #emptyfiltered="scope">
                        <div class="text-center my-2">
                            {{ scope.emptyText }}
                        </div>
                    </template>
                </b-table>
                <user-status :user="user" @updateStatus="updateStatus"/>
            </div>
        </div>
        <div v-if="totalRequests > perPage" class="pt-3">
            <b-pagination first-number last-number :total-rows="totalRequests" :per-page="perPage" v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import parseDate from '@/mixins/parseDate'
    import BanStatus from "@/components/user/BanStatus";
    import UserStatus from "@/components/modals/UserStatus";
    import { errorsAlertApi } from '@/helpers/errorsAlertApi'

    export default {
        name: "claim",
        components: {UserStatus, BanStatus},
        mixins: [parseDate],
        methods: {
            ...mapActions([
                'getClaim',
                'setUserStatus'
            ]),
            claimsProvider(ctx){
                const data = {
                    page: ctx.currentPage
                };

                if(ctx.sortBy){
                    data.sortBy = ctx.sortBy;
                    data.sort = ctx.sortDesc ? 'desc' : 'asc'
                }

                return this.getClaim(data).then(requestsApi => {
                    this.claims = requestsApi.results;
                    this.totalRequests = requestsApi.totalCount;
                    return this.claims
                })
                    .catch(error => {
                        errorsAlertApi(error);
                        return []
                    });
            },
            handleBan(user){
                if(user){
                    this.user = user;
                    this.$bvModal.show('user-status');
                }
            },
            updateStatus(user){
                this.setUserStatus(user).then(() => {
                    this.$bvModal.hide('user-status');
                    this.$refs.claimTable.refresh();
                })
                    .catch(error => {
                        errorsAlertApi(error);
                    });
            },
        },
        data(){
            return{
                claims: [],
                isBusy: false,
                user: null,
                fields: [
                    {
                        key: 'owner',
                        label: 'Кто жалуется',
                        thStyle: {
                            width: '20%'
                        },
                        sortable: true
                    }, {
                        key: 'target',
                        label: 'На кого жалоба',
                        thStyle: {
                            width: '20%'
                        },
                        sortable: true
                    }, {
                        key: 'description',
                        label: 'Причина',
                        thStyle: {
                            width: '30%'
                        },
                        sortable: true
                    }, {
                        key: 'createdAt',
                        label: 'Дата жалобы',
                        thStyle: {
                            width: '13%'
                        },
                        sortable: true
                    }, {
                        key: 'status',
                        label: 'Статус',
                    }
                ],
                currentPage: 1,
                perPage: 10,
                totalRequests: 0,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .user{
        &-td {
            display: flex;
            align-items: center;
            min-height: 48px;
            img {
                width: 48px;
                height: 48px;
                margin-right: 10px;
                border-radius: 50%;
                overflow: hidden;
                object-fit: cover;
                flex: none;
            }
        }
    }
    .status-cell{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .deleted{
        background: #000;
    }
</style>